<template>

<div class="present" :class="{'is-loading': loading}" v-if="presenting" v-on:click="presenting = false">

	<app-icon icon="loading" class="present-loading" v-if="loading" />

	<div class="present-content" :class="{'is-borderless': presenting.borderless}">
		<img :src="image" v-if="presenting.image" v-on:load="loading = false" />
		<iframe :src="file" v-if="presenting.file" />
		<div v-if="presenting.title">{{ presenting.title }}</div>
		<small>Click anywhere to close</small>
	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			loading: true,
			presenting: false
		}

	},

	created: function() {

		this.$pubsub.$on('present', this.onPresent.bind(this));

	},

	beforeDestroy: function() {

		this.$pubsub.$off('present', this.onPresent.bind(this));

	},

	computed: {

		file: function() {

			return this.presenting.file + '?' + this.$moment.utc().unix()

		},

		image: function() {

			return (this.presenting.image.indexOf('data:') === 0) ? this.presenting.image : this.presenting.image + '?' +this.$moment.utc().unix()

		}

	},

	methods: {

		onPresent: function(e) {

			this.presenting = e

			if (this.presenting.file) {

				this.loading = false

			} else {

				this.loading = true

			}

		}

	}

}

</script>

<style scoped>

.present {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-color: rgba(0, 0, 0, 0.75);
	display: flex;
	justify-content: center;
	align-items: center;
}

.present-loading {
	font-size: 32px;
	color: #fff;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.present-content {
	background-color: #fff;
	padding: 10px;
}

.present.is-loading .present-content {
	opacity: 0;
}

.present-content.is-borderless {
	padding: 0px;
}

.present-content iframe {
	border: 0px;
	width: 640px;
	height: 640px;
}

.present-content img {
	display: block;
	max-height: 600px;
	max-width: 600px;
}

.present-content > div {
	text-align: center;
	font-size: 14px;
	color: #333;
	padding-top: 10px;
	font-weight: 400;
}

.present-content > small {
	color: #fff;
	opacity: 0.5;
	position: absolute;
	font-weight: 300;
	bottom: -24px;
	font-size: 12px;
	text-align: center;
	left: 0px;
	width: 100%;
}

</style>
