<template>

<div class="screen-content" :class="{'is-iconmenu': iconMenu, 'is-hidden': alwaysHidden, 'is-empty': !hasConventions, 'is-nohead': noHead, 'is-fullscreen': !$loggedin || plain || $route.meta.isFullscreen, 'is-natural': isNatural, 'is-offset': offset}">

	<slot></slot>

	<com-present />

</div>

</template>

<script>

import comPresent from './content/Present'

export default {

	props: ['offset', 'iconMenu', 'noHead', 'alwaysHidden', 'plain', 'isNatural'],

	components: {
		'com-present': comPresent
	},

	computed: {

		hasConventions: function() {

			return this.$store.getters['session/conventions'].length

		}

	}

}

</script>

<style scoped>

.screen-content:not(.is-natural) {
	position: fixed;
	left: var(--panel-width); 
	top: var(--head-height);
	bottom: 0px;
	right: 0px;
	background-color: #f2f7ff;
	transition: all 100ms ease-in-out;
	z-index: 3;
}

.screen-content:not(.is-natural).is-offset {
	transform: translateX(-320px);
}

.is-mobile .screen-content:not(.is-natural).is-offset {
	transform: translateX(-100%);
}

.screen-content.is-hidden,
.is-device .screen-content:not(.is-natural) {
	left: 0px;
	top: 64px;
}

.is-mobile .screen-content:not(.is-natural) {
	top: 48px;
	bottom: 0px;
}

.screen-content.is-empty {
	top: 0px;
}

.screen-content:not(.is-natural).is-fullscreen {
	top: 0px;
	left: 0px;
	z-index: 10;
}

.screen-content.is-iconmenu {
	left: 40px!important;
}

.screen-content.is-nohead {
	top: 0px!important;
}

</style>
